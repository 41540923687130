<template>
    <div>
        <v-alert
            prominent
            border="top"
            color="accent"
            outlined
            icon="mdi-account-check"
        >
            Rent Well maintains authorizations for all students. It is not
            necessary for you to authorize the application on behalf of your
            student.
        </v-alert>
    </div>
</template>

<script>
export default {
    name: "application-on-behalf-authorize",
    props: {
        nextStepDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        this.$emit("update:nextStepDisabled", false);
    },
};
</script>

<style lang="scss" scoped>
</style>