var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-alert",
        {
          attrs: {
            prominent: "",
            border: "top",
            color: "accent",
            outlined: "",
            icon: "mdi-account-check"
          }
        },
        [
          _vm._v(
            " Rent Well maintains authorizations for all students. It is not necessary for you to authorize the application on behalf of your student. "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }